:root {
  --main-black:  #0D1B2A;
  --accent-blue: #30ADC9;
  --accent-orange: #FC7753;
  --accent-gray: #A9AFB6;
  --background-gray: #F8F9FA;

  scroll-behavior: smooth;
}

.App {
  text-align: center;
  min-height: 100vh;
  background-color: var(--background-gray);
  color: var(--main-black);
}

.container {
  margin: auto;
  padding: 0 1em;
}

@media (min-width: 768px) {
  .container {
    max-width: 750px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 970px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 970px;
  }
}

h1 {
  color: var(--accent-blue)
}

a.text-link {
  text-decoration: none;
  position: relative;
  color: var(--main-black);
  box-shadow: 0 -.5em 0 rgba(252, 119, 83, 0.4) inset;
  transition: ease-out 0.25s;
}
a.text-link:hover {
  box-shadow: 0 -1.3em 0 rgba(252, 119, 83, 0.4) inset;
}

b {
  color: var(--accent-blue);
}