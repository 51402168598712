/* .landing {
  display: flex;
  min-height: calc(100vh - 5em);
  align-items: center;
} */

.description {
  /* width: 60%; */
  /* position: relative; */
  text-align: left;
}

#big-name{
  font-size: 4em;
}

/* .description .text {
  position: relative;
  left: min(4em + 28%, 23.6em);
  width: max(100% - 4em - 28%, 100% - 23.6em);
  margin-top: -20%;
} */


/* a.text-link::after {
  content: "";
  background-color: rgba(252, 119, 83, 0.5);
  width: calc(100% + 0.2em);
  height: 30%;
  position: absolute;
  left: -0.1em;
  bottom: 0.1em;
  transition: ease-out 0.25s;
}

a.text-link:hover::after {
  height: 87%;
} */

a.icon-link {
  color: var(--accent-gray);
  margin-right: 0.5em;
  font-size: 20px;
  transition: ease-in 0.1s; 
}
a.icon-link:hover {
  color: var(--main-black);
}

.scrollChevron {
  position: absolute;
  bottom: 20px;
  left: 50%;
  display: none;
}

@media (min-height: 860px) {
  .scrollChevron {
    display: block;
  }
}

#portfolio {
  min-height: 100vh;
}

.hp-accomplish {
  padding-top: 3em;
}
.hp-accomplish hr {
  border: none;
  border-bottom: 1px solid #C4C4C4;
  padding-top: 3em;
  margin-bottom: 3em;
}


.accomplish-text {
  text-align: left;
}
.accomplish-text h1 {
  margin-top: 0em;
  margin-bottom: 0em;
}
.accomplish-text h1 {
  margin-top: 0em;
  margin-bottom: 0em;
}
.accomplish-text span {
  font-size: 36px;
  font-weight: bold;
}
.accomplish-text input {
  position: absolute;
  font-size: 36px;
  font-weight: bold;
  border: none;
  color: var(--main-black);
  background: transparent;
  font-family: 'Red Hat Text', 'Roboto', 'Ubuntu', sans-serif;
  width: 100%;
}
.accomplish-text input:focus {
  outline: none;
  background: transparent;
}

.accomplish-input-container {
  display: flex;
}
.accomplish-input-overlay {
  flex-grow: 1;
  position: relative;
  z-index: 0;
}

.accomplish-input-gray {
  font-size: 36px;
  font-weight: bold;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: -1;
  color: var(--accent-gray);
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
}