@import url(https://fonts.googleapis.com/css2?family=Red+Hat+Text:wght@400;700&display=swap);
body {
  margin: 0;
  font-family: 'Red Hat Text', 'Roboto', 'Ubuntu', sans-serif;
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --main-black:  #0D1B2A;
  --accent-blue: #30ADC9;
  --accent-orange: #FC7753;
  --accent-gray: #A9AFB6;
  --background-gray: #F8F9FA;

  scroll-behavior: smooth;
}

.App {
  text-align: center;
  min-height: 100vh;
  background-color: #F8F9FA;
  background-color: var(--background-gray);
  color: #0D1B2A;
  color: var(--main-black);
}

.container {
  margin: auto;
  padding: 0 1em;
}

@media (min-width: 768px) {
  .container {
    max-width: 750px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 970px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 970px;
  }
}

h1 {
  color: #30ADC9;
  color: var(--accent-blue)
}

a.text-link {
  text-decoration: none;
  position: relative;
  color: #0D1B2A;
  color: var(--main-black);
  box-shadow: 0 -.5em 0 rgba(252, 119, 83, 0.4) inset;
  transition: ease-out 0.25s;
}
a.text-link:hover {
  box-shadow: 0 -1.3em 0 rgba(252, 119, 83, 0.4) inset;
}

b {
  color: #30ADC9;
  color: var(--accent-blue);
}
.navbar {
  padding-top: 1em;
  height: 4em;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.nav-link {
  padding-left: 3em;
  padding-right: 3em;
  font-family: 'Red Hat Text', 'Roboto', 'Ubuntu', sans-serif;
  font-size: 20px;
  font-weight: bold;
  color: #0D1B2A;
  text-decoration: none;
}

.nav-link:hover {
  color: #30ADC9;
}
/* .landing {
  display: flex;
  min-height: calc(100vh - 5em);
  align-items: center;
} */

.description {
  /* width: 60%; */
  /* position: relative; */
  text-align: left;
}

#big-name{
  font-size: 4em;
}

/* .description .text {
  position: relative;
  left: min(4em + 28%, 23.6em);
  width: max(100% - 4em - 28%, 100% - 23.6em);
  margin-top: -20%;
} */


/* a.text-link::after {
  content: "";
  background-color: rgba(252, 119, 83, 0.5);
  width: calc(100% + 0.2em);
  height: 30%;
  position: absolute;
  left: -0.1em;
  bottom: 0.1em;
  transition: ease-out 0.25s;
}

a.text-link:hover::after {
  height: 87%;
} */

a.icon-link {
  color: var(--accent-gray);
  margin-right: 0.5em;
  font-size: 20px;
  transition: ease-in 0.1s; 
}
a.icon-link:hover {
  color: var(--main-black);
}

.scrollChevron {
  position: absolute;
  bottom: 20px;
  left: 50%;
  display: none;
}

@media (min-height: 860px) {
  .scrollChevron {
    display: block;
  }
}

#portfolio {
  min-height: 100vh;
}

.hp-accomplish {
  padding-top: 3em;
}
.hp-accomplish hr {
  border: none;
  border-bottom: 1px solid #C4C4C4;
  padding-top: 3em;
  margin-bottom: 3em;
}


.accomplish-text {
  text-align: left;
}
.accomplish-text h1 {
  margin-top: 0em;
  margin-bottom: 0em;
}
.accomplish-text h1 {
  margin-top: 0em;
  margin-bottom: 0em;
}
.accomplish-text span {
  font-size: 36px;
  font-weight: bold;
}
.accomplish-text input {
  position: absolute;
  font-size: 36px;
  font-weight: bold;
  border: none;
  color: var(--main-black);
  background: transparent;
  font-family: 'Red Hat Text', 'Roboto', 'Ubuntu', sans-serif;
  width: 100%;
}
.accomplish-text input:focus {
  outline: none;
  background: transparent;
}

.accomplish-input-container {
  display: flex;
}
.accomplish-input-overlay {
  flex-grow: 1;
  position: relative;
  z-index: 0;
}

.accomplish-input-gray {
  font-size: 36px;
  font-weight: bold;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: -1;
  color: var(--accent-gray);
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
}
.portf-section-header {
  font-weight: normal;
  font-size: 32px;
  position: relative;
  margin-bottom: 1em;
}

.portf-section-header::after {
  content:"";
  position: absolute;
  bottom: -5px;
  left: 50%;
  margin-left:-50px;
  width: 100px;
  height: 1px;
  background-color: var(--main-black);
}
.portfolio-item {
  display: flex;
  margin: 0.7em 0em;
}

.portfolio-item-left {
  width: 30%;
  text-align: left;
  font-size: 24px;
}
.portfolio-item-left p{
  margin: 0em 0em;
}
.portfolio-item-org {
  font-weight: bold;
}
.portfolio-item-title {
  font-style: italic;
}

.portfolio-item-right {
  width: 70%;
  text-align: left;
}
.portfolio-item-right ul{
  margin-top: 0em;
}
.portfolio-item-right li:not(:last-child) {
  margin-bottom: 0.4em;
}
.App-header {
  height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.teaching-page {
    text-align: left;
}
.readings-page {
    text-align: left;
}

.readings-page p+ul{
    margin-top: -1em;
}
